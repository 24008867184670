<template>
  <div class="pa-0 mb-4">
    <div v-if="item.info">
      <v-row class="">
        <v-col>
          {{ $t("donorId") }} {{ item.info.donorId }}
          <span v-if="item.info.fullname" class="ml-2"
            >({{ item.info.fullname }})</span
          >
          <v-chip v-if="item.info.pathToFile" outlined class="ml-2">
            <v-icon xsmall class="mr-2">mdi-folder-account-outline</v-icon>
            {{ item.info.pathToFile }}
          </v-chip>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            x-small
            outlined
            depressed
            color="primary"
            @click="$emit('editInfo')"
          >
            <v-icon x-small class="mr-2">mdi-pencil</v-icon>
            {{ $t("donor.edit") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-for="(account, i) in item.accounts" :key="i">
      <v-divider v-if="i"></v-divider>
      <account-item :account="account"></account-item>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import AccountItem from "./AccountItem";

export default {
  name: "AccountGroup",
  components: { AccountItem },
  props: {
    item: Object,
    scopes: Array,
  },
  computed: {
    accounts: function () {
      let accounts = [];
      let item = this.item;
      this.scopes.forEach(function (scope) {
        const account = _.get(item, scope);
        if (!_.isEmpty(account)) {
          accounts.push(account);
        }
      });

      return accounts;
    },
  },
};
</script>

<style scoped>
.theme--light.v-divider {
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.08);
}
</style>
