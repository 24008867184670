<template>
  <v-col sm="6" md="4" lg="2">
    <v-autocomplete
      :items="regionItems"
      item-text="title"
      item-value="id"
      :loading="isRegionLoading"
      :search-input.sync="regionSearch"
      menu-props="closeOnContentClick"
      @change="regionSearch = ''"
      deletable-chips
      multiple
      hide-no-data
      hide-selected
      small-chips
      name="region"
      :label="label"
      v-model="itemValue"
      @input="handleInput"
    >
      <v-icon
        slot="append"
        :title="$t('removeFilter')"
        @click="$emit('remove')"
      >
        mdi-close
      </v-icon>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { FETCH_REGION_META } from "@/store/actions/donor";

export default {
  name: "RegionSearch",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Array,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
      closeOnContentClick: true,
      //List of selected related items
      //Filter values status
      isRegionLoading: false,
      //Filter values
      items: [],
      //Filter entered value
      regionSearch: "",
    };
  },
  watch: {
    regionSearch: function () {
      this.fetchRegions();
    },
    value: {
      handler: function () {
        this.itemValue = this.value;
        this.fetchRegions();
      },
      deep: true,
    },
  },
  computed: {
    regionItems: function () {
      let items = this.items;
      if (this.allowEmpty) {
        items.unshift({
          id: "notSet",
          title: this.$t("notSet"),
        });
      }

      return items;
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
    fetchRegions: function () {
      this.isRegionLoading = true;
      this.$store
        .dispatch("account/donor/" + FETCH_REGION_META, {
          title: this.regionSearch,
          ids: this.itemValue,
        })
        .then(
          (payload) => {
            this.isRegionLoading = false;
            this.items = payload.data;
          },
          () => {}
        )
        .catch(() => {});
    },
  },
  mounted: function () {
    this.fetchRegions();
  },
};
</script>
