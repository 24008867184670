<template>
  <v-card>
    <v-card-title
      v-if="item"
      class="headline primary lighten-1 white--text justify-space-between"
    >
      {{ $t("donor.edit") }} {{ item.donorId }}
      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-center pa-10" v-if="dataLoading">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-if="!dataLoading" class="pa-3 pt-4">
        <v-row>
          <v-col>
            <v-text-field
              name="fullname"
              :label="attributeLabels.fullname"
              :error-messages="donorErrors.fullname"
              v-model="donor.fullname"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              name="pathToFile"
              :label="attributeLabels.pathToFile"
              :error-messages="donorErrors.pathToFile"
              v-model="donor.pathToFile"
              type="text"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions v-if="!dataLoading">
      <v-spacer></v-spacer>
      <v-btn color="grey" text @click="$emit('close')">Close</v-btn>
      <v-btn color="blue darken-1" text @click="updateItem"> Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";
import { PATCH_DONOR } from "@/store/actions/donor";

import { maxLength } from "vuelidate/lib/validators";
import validator from "@/mixin/validator";

export default {
  name: "DonorForm",
  mixins: [validator],
  data() {
    return {
      modal: 0,
      dataLoading: false,
      attributeLabels: {
        fullname: this.$t("donor.attribute.fullname"),
        pathToFile: this.$t("donor.attribute.pathToFile"),
      },
      donor: this.item,
    };
  },
  props: {
    item: Object,
  },
  computed: {
    donorErrors: function () {
      return _.merge(
        {
          fullname: "",
          pathToFile: "",
        },
        this.validator.errors.donor
      );
    },
  },
  methods: {
    updateItem: function () {
      if (this.validate()) {
        this.dataLoading = true;
        const { donor } = this;
        this.$store
          .dispatch("account/donor/" + PATCH_DONOR, { donor })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.$emit("submitted");
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
  validations: {
    donor: {
      fullname: {
        maxLength: maxLength(128),
      },
      pathToFile: {
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
