<template>
  <div class="about">
    <v-row>
      <v-col>
        <h1>{{ $t("account.listTitle") }}</h1>
      </v-col>
      <v-col class="text-end">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small color="primary" dark v-bind="attrs" v-on="on">
              {{ $t("account.sync") }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in scopes"
              :key="index"
              @click="confirmSync(item)"
            >
              <v-list-item-title>
                {{ getServiceName({ scope: item }) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          small
          color="primary"
          class="ml-2"
          :loading="exportLoading"
          :disabled="exportLoading"
          @click="exportItems"
        >
          {{ $t("account.export") }}
          <template v-slot:loader>
            <span>{{ $t("account.exportProcess") }}</span>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <!--    Filter controls & ordering begin -->
    <div class="d-flex justify-space-between">
      <v-spacer></v-spacer>
      <div class="d-flex">
        <v-select
          item-text="label"
          item-value="attribute"
          :items="attributes"
          :label="$t('orderBy')"
          v-model="sort.sortBy"
          style="max-width: 180px"
        ></v-select>
        <span v-if="sort.sortBy" class="mt-5 ml-2">
          <v-icon
            v-if="sort.desc"
            :title="$t('sortAsc')"
            @click="sort.desc = false"
          >
            mdi-sort-descending
          </v-icon>
          <v-icon v-else :title="$t('sortDesc')" @click="sort.desc = true">
            mdi-sort-ascending
          </v-icon>
        </span>
      </div>
    </div>
    <account-search v-if="pageReady" :labels="attributeLabels"></account-search>
    <v-divider></v-divider>
    <!--    Filter controls & ordering end -->
    <div class="text-right">
      <span v-if="totalCount > 0" class="ml-2 body-2">
        {{
          $t("paginationSummary", {
            from: offset + 1,
            to: maxItem,
            totalCount: totalCount,
          })
        }}
      </span>
    </div>
    <div>
      <!-- Data loader -->
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <div>
        <!--    Data table     -->
        <v-data-iterator
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          :items="items"
          :no-data-text="$t('no-data')"
        >
          <template v-slot:header>
            <!--    Table header begin -->
            <div
              v-if="totalCount > 0"
              class="d-sm-none d-md-none d-lg-block px-4 py-0 pb-0"
            >
              <v-row class="grey--text body-2">
                <v-col cols="2" lg="2" class="align-self-end">
                  {{ $t("account.attribute.userId") }}
                </v-col>
                <v-col lg="2" class="align-self-end">
                  {{ $t("account.attribute.domain") }}
                  / {{ $t("account.attribute.language") }} /
                  {{ $t("account.attribute.gender") }}
                  <div>{{ $t("account.attribute.regionId") }}</div>
                </v-col>
                <v-col lg="2" class="align-self-end">
                  {{ $t("account.attribute.tags") }}
                </v-col>
                <v-col lg="1" class="align-self-end">
                  {{ $t("account.attribute.friendsCount") }}
                </v-col>
                <v-col lg="1" class="align-self-end">
                  {{ $t("account.attribute.type") }} /
                  {{ $t("account.attribute.stage") }}
                </v-col>
                <v-col lg="1" class="align-self-end">
                  {{ $t("account.attribute.updatedAt") }}
                </v-col>
                <v-col lg="2" class="align-self-end text-center">
                  {{ $t("account.attribute.status") }} /
                  {{ $t("account.attribute.changedAt") }}
                </v-col>
                <v-col lg="1" class="align-self-end">
                  {{ $t("account.attribute.errorCode") }}
                </v-col>
              </v-row>
            </div>
            <!--    Table header end -->
          </template>
          <template v-slot:default="props">
            <account-group
              v-for="(item, i) in props.items"
              :key="i"
              :item="item"
              :scopes="scopes"
              @editInfo="editItem(item.info)"
            ></account-group>
          </template>
        </v-data-iterator>
        <!--    Table pagination -->
        <div class="text-right">
          <span v-if="totalCount > 0" class="ml-2 body-2">
            {{
              $t("paginationSummary", {
                from: offset + 1,
                to: maxItem,
                totalCount: totalCount,
              })
            }}
          </span>
        </div>
      </div>
    </div>
    <!--    Pagination    -->
    <div v-if="pageCount > 1" class="text-center pt-6">
      <v-pagination
        v-model="pagination.page"
        :length="pageCount"
        :total-visible="9"
      ></v-pagination>
    </div>
    <!--    Donor form dialog    -->
    <v-dialog v-model="editDialog" max-width="650px">
      <donor-form
        :item="selectedItem"
        @close="closeEditDialog"
        @submitted="closeEditDialog"
      ></donor-form>
    </v-dialog>
    <v-dialog v-if="syncScope" v-model="syncDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          {{ $t("syncConfirmation") }}
          <v-btn icon color="white" @click="closeSyncConfirmation">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          {{
            $t("account.syncConfirmationText", {
              scope: getServiceName({ scope: syncScope }),
            })
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeSyncConfirmation">{{
            $t("close")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="syncAccounts(syncScope)">
            {{ $t("sync") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

import { mapGetters, mapState } from "vuex";

import {
  FETCH_ACCOUNTS,
  EXPORT_ACCOUNTS,
  SYNC_ACCOUNTS,
} from "@/store/actions/donor";
import { FETCH_SERVICES } from "@/store/actions/service";

import {
  multipleFilters,
  defaultFilters,
} from "@/store/modules/account/donor/state";

import AccountGroup from "@/views/account/list/AccountGroup";
import AccountSearch from "@/views/account/list/AccountSearch";
import DonorForm from "@/views/account/DonorForm";

import BaseListPage from "@/views/base/BaseListPage";
import { SET_FILTER } from "@/store/actions/donor";

export default {
  name: "AccountList",
  extends: BaseListPage,
  components: { AccountGroup, AccountSearch, DonorForm },
  data() {
    return {
      //Whether export in process
      exportLoading: false,

      //List of translated attribute label
      attributeLabels: {
        donorId: this.$t("account.attribute.donorId"),
        userId: this.$t("account.attribute.userId"),
        age: this.$t("account.attribute.age"),
        fullname: this.$t("account.attribute.fullname"),
        domain: this.$t("account.attribute.domain"),
        stage: this.$t("account.attribute.stage"),
        target: this.$t("account.attribute.target"),
        status: this.$t("account.attribute.status"),
        gender: this.$t("account.attribute.gender"),
        birthday: this.$t("account.attribute.birthday"),
        errorCode: this.$t("account.attribute.errorCode"),
        friendsCount: this.$t("account.attribute.friendsCount"),
        proxy: this.$t("account.attribute.proxy"),
        display: this.$t("account.attribute.display"),
        tags: this.$t("account.attribute.tags"),
        language: this.$t("account.attribute.language"),
        enabled: this.$t("account.attribute.enabled"),
        hasProxy: this.$t("account.attribute.hasProxy"),
        type: this.$t("account.attribute.type"),
        statusChangedRange: this.$t("account.attribute.statusChangedAt"),
        statusChangedAt: this.$t("account.attribute.statusChangedAt"),
        statusChangedFrom: this.$t("account.attribute.statusChangedFrom"),
        statusChangedTo: this.$t("account.attribute.statusChangedTo"),
        query: this.$t("account.attribute.query"),
        url: this.$t("account.attribute.url"),
        proxyPackages: this.$t("account.attribute.proxyPackage"),
        hasLinkedAccount: this.$t("account.attribute.hasLinkedAccount"),
        regionId: this.$t("account.attribute.regionId"),
        updatedAt: this.$t("account.attribute.updatedAt"),
      },

      //List of attributes allowed for ordering
      orderAttributes: [
        "donorId",
        "stage",
        "status",
        "errorCode",
        "domain",
        "gender",
        "birthday",
        "friendsCount",
        "proxy",
      ],
      syncDialog: false,
      syncScope: null,
      defaultFilters: _.merge({}, defaultFilters),
      multipleFilters,
    };
  },
  computed: {
    ...mapGetters("service", ["scopes", "getServiceName"]),
    ...mapGetters("account/donor", [
      "offset",
      "itemsPerPage",
      "totalCount",
      "items",
      "pageCount",
      "formattedFilters",
    ]),
    ...mapState("account/donor", [
      "filters",
      "pagination",
      "sort",
      "emptyFilters",
    ]),
    //Get order number of last showed row
    maxItem: function () {
      return Math.min(this.totalCount, this.offset + this.itemsPerPage);
    },
    //Get formatted attribute labels
    attributes: function () {
      let items = [];
      let attributeLabels = this.attributeLabels;

      this.orderAttributes.forEach(function (attribute) {
        items.push({
          attribute,
          label: _.get(attributeLabels, attribute, ""),
        });
      });
      return items;
    },
  },
  methods: {
    loadItems: function () {
      this.dataLoading = true;
      this.$store
        .dispatch("account/donor/" + FETCH_ACCOUNTS)
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    exportItems: function () {
      this.$store
        .dispatch("account/donor/" + EXPORT_ACCOUNTS)
        .then(
          (payload) => {
            const { data } = payload;
            if (data.taskHash) {
              this.$notify({
                group: "messages",
                title: this.$t("account.export"),
                text: this.$t("account.exportStarted"),
              });
              this.$socket.emit("subscribe", {
                channel: "export",
                subChannel: data.taskHash,
              });
              this.exportLoading = true;
            } else {
              this.$notify({
                group: "error",
                type: "error",
                title: this.$t("account.export"),
                text: this.$t("account.exportFailed"),
              });
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    loadServices: function () {
      this.dataLoading = true;
      this.$store
        .dispatch("service/" + FETCH_SERVICES, null, { root: true })
        .catch(() => {});
    },
    editItem: function (item) {
      this.selectedItem = item;
      this.editDialog = true;
    },
    closeEditDialog: function () {
      this.selectedItem = null;
      this.editDialog = false;
    },
    confirmSync: function (scope) {
      this.syncScope = scope;
      this.syncDialog = true;
    },
    syncAccounts: function (scope) {
      this.$store
        .dispatch("account/donor/" + SYNC_ACCOUNTS, { scope })
        .then(
          () => {
            this.closeSyncConfirmation();
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    closeSyncConfirmation: function () {
      this.syncScope = null;
      this.syncDialog = false;
    },
    applyFilters: function (data) {
      this.$store.commit("account/donor/" + SET_FILTER, data.data);
    },
  },
  mounted() {
    if (!this.applyRouteParams()) {
      this.loadItems();
    }
    this.loadServices();
  },
  sockets: {
    export: function (msg) {
      const { payload } = msg;
      if (payload && payload.status === "failed") {
        this.$notify({
          group: "error",
          type: "error",
          title: this.$t("account.export"),
          text: this.$t("account.exportFailed"),
        });
      }
      this.exportLoading = false;
    },
  },
};
</script>

<style scoped>
.theme--light.v-divider {
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.08);
}
</style>
