<template>
  <v-col sm="12" md="7" lg="5">
    <v-autocomplete
      :items="items"
      item-text="title"
      item-value="id"
      :loading="isPackageLoading"
      :search-input.sync="packageSearch"
      menu-props="closeOnContentClick"
      @change="packageSearch = ''"
      deletable-chips
      multiple
      hide-no-data
      hide-selected
      small-chips
      name="packages"
      :label="label"
      v-model="itemValue"
      @input="handleInput"
    >
      <v-icon
        slot="append"
        :title="$t('removeFilter')"
        @click="$emit('remove')"
      >
        mdi-close
      </v-icon>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { FETCH_META } from "@/store/actions/proxy-package";

export default {
  name: "ProxyPackageSearch",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Array,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
      closeOnContentClick: true,
      //List of selected related items
      //Filter values status
      isPackageLoading: false,
      //Filter values
      items: [],
      //Filter entered value
      packageSearch: "",
    };
  },
  watch: {
    packageSearch: function () {
      this.fetchPackages();
    },
    value: {
      handler: function () {
        this.itemValue = this.value;
        this.fetchPackages();
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
    fetchPackages: function () {
      this.isPackageLoading = true;
      this.$store
        .dispatch("proxyPackage/" + FETCH_META, {
          title: this.packageSearch,
          ids: this.itemValue,
        })
        .then(
          (payload) => {
            this.isPackageLoading = false;
            this.items = payload.data;
          },
          () => {}
        )
        .catch(() => {});
    },
  },
  mounted: function () {
    this.fetchPackages();
  },
};
</script>
