<template>
  <v-col sm="6" md="4" lg="2">
    <v-checkbox
      :true-value="1"
      :false-value="0"
      name="display"
      :label="label"
      v-model="itemValue"
      @change="handleInput"
      data-bool-search
    >
      <v-icon
        slot="append"
        :title="$t('removeFilter')"
        @click="$emit('remove')"
      >
        mdi-close
      </v-icon>
    </v-checkbox>
  </v-col>
</template>

<script>
export default {
  name: "BoolSearch",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {},
  },
  data: function () {
    return {
      itemValue: this.value,
    };
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
  },
  mounted() {
    if (this.value === null) {
      this.$emit("input", 0);
    }
  },
};
</script>
