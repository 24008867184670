<template>
  <v-sheet outlined class="mt-1">
    <router-link
      class="text-decoration-none grey--text text--darken-4"
      :to="{
        name: 'AccountPage',
        params: {
          gobellsId: account.gobellsId,
          scope: account.target,
          userId: account.userId,
        },
      }"
    >
      <v-row class="pa-3 pl-4 align-center">
        <v-col cols="2" sm="6" md="4" lg="2">
          <v-row class="flex-nowrap">
            <v-col cols="2" sm="2" md="2" lg="2">
              <v-icon>mdi-{{ targetIcon }}</v-icon>
            </v-col>
            <v-col class="text-no-wrap">
              {{ account.userId }}
              <a
                :href="account.fullUrl"
                @click.prevent="redirect(account.fullUrl)"
                target="_blank"
                class="grey--text text--darken-4 text-decoration-none"
              >
                <v-icon small>mdi-open-in-new</v-icon>
              </a>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2" sm="6" md="4" lg="2">
          {{ title }}
          <flag :iso="getAbrByLanguage({ language: account.language })" />
          <v-icon small>
            mdi-gender-{{ getSymbolByGender({ gender: account.gender }) }}
          </v-icon>
          <div v-if="account.region">{{ account.region.title }}</div>
        </v-col>
        <v-col cols="2" sm="6" md="4" lg="2">
          <v-icon small v-if="account.tags.length">mdi-pound</v-icon>
          <v-chip
            small
            outlined
            v-for="(tag, i) in account.tags"
            :key="i"
            class="ml-1"
          >
            {{ tag.title }}
          </v-chip>
        </v-col>
        <v-col cols="1" sm="3" md="2" lg="1">
          <v-icon>mdi-account-multiple</v-icon> {{ account.friendsCount }}
        </v-col>
        <v-col cols="1" sm="3" md="2" lg="1">
          {{ $t("account.attribute.type") }} {{ account.type }}<br />
          {{ $t("account.stage") }} {{ account.stage }}
        </v-col>
        <v-col cols="1" sm="3" md="2" lg="1">
          <div class="text-center caption">{{ account.updatedAt }}</div>
        </v-col>
        <v-col cols="2" sm="6" md="5" lg="2">
          <div class="d-flex justify-space-around">
            <v-chip
              small
              outlined
              :color="getColorByStatus({ status: account.status })"
              class="px-2"
            >
              {{ $t("account.status")[account.status] }}
            </v-chip>
          </div>
          <div class="text-center caption">{{ account.statusChangedAt }}</div>
        </v-col>
        <v-col cols="1" sm="2" md="1" lg="1" class="d-flex flex-nowrap">
          <v-icon v-if="account.errorCode > 1" color="red lighten-3">
            mdi-alert-circle
          </v-icon>
          <v-icon v-if="account.errorCode === 1" color="green lighten-3">
            mdi-check-circle
          </v-icon>
          <span v-if="account.errorCode > 1" class="red--text text--lighten-3">
            {{ account.errorCode }}
          </span>
        </v-col>
      </v-row>
    </router-link>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountItem",
  props: {
    account: Object,
  },
  computed: {
    title: function () {
      return this.account.fullname
        ? this.account.fullname
        : this.account.domain;
    },
    targetIcon: function () {
      return this.account.target === "vkontakte"
        ? "alpha-b-box"
        : this.account.target;
    },
    ...mapGetters("account/donor", [
      "getTagColorByCategory",
      "getSymbolByGender",
      "getAbrByLanguage",
      "getColorByStatus",
    ]),
    ...mapGetters("service", ["getServiceName"]),
  },
  methods: {
    redirect: function (url) {
      window.open(url, "_blank").focus();
    },
  },
};
</script>
